* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body {
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
	font-family: "Open Sans", sans-serif !important;
}

p,
h6,
h5,
h4,
h3,
h2,
h1,
a,
span,
button,
input,
label,
textarea,
ul,
li {
	margin: 0px;
	padding: 0px;
	font-family: "Open Sans", sans-serif !important;
}

:root {
	--green: #019550;
	--primary: #0084ff;
	--dark-gray-1: #212529;
	--black: #000000;
	--white: #ffffff;
	--light-gray-1: #f6f6f6;
}

.mainSection {
	display: flex;
	position: relative;
	padding-left: 58px;
}
.mainSection.bgLight {
	background-color: #f5f5f5;
}
.wrapperArea {
	width: 100%;
	flex: 1;
}
.wrapperContainer {
	display: flex;
	height: calc(100vh - 66px);
}
.wrappePageArea {
	width: 100%;
	flex: 1;
	overflow-y: auto;
	height: 100%;
}
.wrappePageArea.wrappePd {
	padding: 20px 20px;
}
ul {
	list-style-type: none;
}

.eye-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	color: gray;
	cursor: pointer;
}

/* New Responsive Styles Start */
.tableScrollArea {
	width: 100%;
	overflow-x: auto;
}
.tableScrollArea table {
	min-width: 800px;
}
.tableScrollArea.userListViewTable .tblFlexBar .tblText {
	white-space: nowrap;
}
.tableScrollArea.userListViewTable .tableArea thead th,
.tableScrollArea.userListViewTable .tableArea tbody td {
	padding-right: 10px;
}
.tableScrollArea .emptyBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: fit-content;
	margin: 0 auto;
	text-align: center;
}
.tableScrollArea .emptyBox img {
	margin: 0 auto 10px;
}

.fade.modal {
	z-index: 9999999 !important;
}
/* New Responsive Styles end */
