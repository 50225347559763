@media (min-width: 1500px) {
	#root .formContainer {
		max-width: 1400px;
		margin: 0 auto;
	}
}
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
	.tableScrollArea.userListViewTable .tableArea tbody .tblTd .tblText {
		white-space: nowrap;
	}
}
@media (max-width: 768px) {
	#root .formContainer {
		padding: 20px;
	}
	.formContainer .tabsList.nav-tabs {
		border: 0px;
		gap: 15px;
	}
	.formContainer .tabsList .nav-item .nav-link {
		font-size: 15px;
	}
	.formContentArea .tabsArea .tab-content {
		max-height: unset;
		padding-bottom: 20px;
		overflow-x: hidden;
		width: 100%;
	}
	.formContainer .SignInArea {
		display: none;
	}
	.formContentArea .submitBtn {
		width: 100%;
	}
	.formContentArea .tabsArea .formArea {
		padding-right: 0px;
	}
	.formContentArea .smText {
		padding-right: 30px;
	}
	.formContentArea .frmLogo {
		width: 180px;
	}
	.formContentArea .mobSignBtn {
		display: block;
	}
	.signInMoal .modal-dialog .modal-content {
		padding: 30px 15px !important;
		min-height: 78vh !important;
	}
	.signInMoal .modal-dialog .modal-content .modalBodyArea.modal-body {
		padding: 0px;
		position: relative;
		padding-bottom: 40px;
	}
	.signInMoal .mdlFrmLogo {
		margin-top: 40px;
		width: 180px;
	}
	.wrapperContainer .settingSidebar {
		display: none;
	}
	.sidebarArea {
		display: none;
	}
	.mainSection {
		padding-left: 0px !important;
		position: relative;
	}
	.wrapperArea .headerArea {
		display: none;
	}
	.mobHeaderArea {
		display: flex !important;
	}
	.frmModalArea.modal .modal-body {
		padding: 0px;
	}
	.frmModalArea .modal-dialog .modal-content {
		padding: 30px 15px 15px;
	}
	.frmModalArea .modal-dialog .modal-content .modal-header .btn-close {
		top: 22px !important;
		right: 22px !important;
	}
	.frmModalArea .modal-dialog .modal-content .fillPrimarBtn {
		width: 100%;
		height: 44px;
	}
	.frmModalArea .modal-dialog .modal-content .modal-body .smText {
		padding-right: 40px;
	}
	.frmModalArea.modal .modal-dialog {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.form-check.checkBoxbar .circleCheck {
		min-width: 16px;
	}
	.frmModalArea .modal-dialog .modal-content {
		padding: 20px 15px !important;
	}
	.checkboxList .form-check.checkBoxbar {
		align-items: flex-start;
	}
	.mobVtScroll {
		overflow-y: auto !important;
		max-height: 54vh !important;
		overflow-x: hidden !important;
		position: relative !important;
	}
	.wrappePageArea .underLineTabsList.nav-tabs .nav-link {
		font-size: 14px;
	}
	.wrappePageArea .underLineTabsList.nav-tabs {
		gap: 20px;
	}
	.mainSection.bgLight {
		background-color: #f2f2f2 !important;
	}
	.wrapperContainer .wrappePageArea.wrappePd {
		padding: 20px 12px;
	}
	.pageArea .tab-content .pageCard {
		padding: 20px 15px;
	}
	.pageCard .cardBox {
		flex-direction: column;
		align-items: flex-start !important;
		gap: 20px;
	}
	.pageCard .InviteUserBtn.btn-primary {
		width: 100%;
		font-weight: 600;
	}
	.pageCard .cardBox.d-flex.align-items-center.justify-content-between div:nth-child(2) {
		width: 100%;
	}
	.panelList .viewDLink {
		display: none;
	}
	.pageCard .contentCardBox {
		padding: 30px 15px;
	}
	.contentCardBox .cpCreateBtn {
		width: 100%;
	}
	.signInMoal.modal .modal-content .frmMdlBtn {
		width: calc(100% - 0px) !important;
		margin: 0 auto !important;
		font-weight: 600 !important;
		position: absolute;
		left: 0px;
		bottom: 0px;
	}
	.pageCard .form-select.tblSelectInput {
		margin-left: 0px;
		margin-bottom: 15px;
	}
	.tableArea thead tr th,
	.tableArea tbody tr td {
		min-width: 240px !important;
	}
	.mobSideManuArea.offcanvas-body .mobUserBox p {
		word-break: break-all;
	}
}
@media (max-width: 500px) {
}
