.programsRoleModal .modal-content {
  border-radius: 20px;
}
.programsRoleModal.modal .modalBody.modal-body {
  padding: 0;
}
.programsRoleModal.modal .modalBody.modal-body .modalBoxArea {
  padding: 15px 24px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin-bottom: 20px;
}
.programsRoleModal.modal
  .modalBody.modal-body
  .modalBoxArea::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.programsRoleModal.modal
  .modalBody.modal-body
  .modalBoxArea::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 100px;
}

/* Handle */
.programsRoleModal.modal
  .modalBody.modal-body
  .modalBoxArea::-webkit-scrollbar-thumb {
  background: #d2d2d2;
  border-radius: 100px;
}
.programsRoleModal .modal-content .modal-header {
  border: 0px;
  padding: 30px 20px 12px;
}
.prgrmTitle {
  font-size: 12px;
  font-weight: 800;
  color: #212529;
}
.prgrmTitle span {
  font-weight: 600;
}
.mdlPrgmListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px 12px;
  border-bottom: 1px solid #e3e4e4;
}
.mdlPrgmListItem .wdLeftclm {
  width: 100%;
}
.mdlPrgmListItem .wdRightclm {
  width: 100px;
  text-align: right;
  min-width: 100px;
}
.mdlPrgmListItem .h6TextH {
  font-size: 14px;
  font-weight: 700;
  color: #212529;
}
.mdlPrgmListItem .pTextP {
  font-size: 14px;
  color: #212529;
  font-weight: 400;
}
.mdlPrgmListItem:last-child {
  border-bottom: 0px;
}
.programsRoleModal.modal .modal-dialog .btn-close {
  background-image: url("assets/images/icons/cross-icon.svg");
  padding: 0;
  background-size: 30px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 28px;
  right: 28px;
}
