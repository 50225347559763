.pageCard {
	padding: 20px;
	background-color: var(--white);
	border-radius: 10px;
}
.h4MdTitle {
	font-size: 18px;
	font-weight: 700;
	color: #1b1b1b;
}

.panelBar {
	background-color: var(--light-gray-1);
	padding: 8px 15px;
	border: 1px solid #e3e4e4;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
}
.cmpNameTag {
	min-width: 180px;
	width: fit-content;
	height: 40px;
	padding: 0px 15px;
	background-color: var(--primary);
	color: var(--white);
	border: 1px solid var(--primary);
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-weight: 500;
	margin-bottom: 0px;
}
.viewDLink {
	font-weight: 600;
	font-size: 15px;
	color: var(--primary);
}
.panelList {
	margin-bottom: 30px;
}
.smH5Text {
	font-size: 15px;
	color: #1b1b1b;
	font-weight: 700;
}
.cpCreateBtn {
	width: 220px;
	height: 44px;
	text-align: center;
	color: #0b6b3f;
	background-color: #a0cbbd;
	font-size: 14px;
	border: 1px solid #a0cbbd;
	border-radius: 5px;
	font-weight: 500;
}
.contentCardBox {
	background-color: var(--light-gray-1);
	padding: 30px 24px;
	border-radius: 10px;
}
.mdH6Text {
	color: #1b1b1b;
	font-size: 15px;
	font-weight: 700;
	margin-bottom: 5px;
}
.cursorDefault {
	cursor: default !important;
}
