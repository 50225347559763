.wizardSection {
	background-color: #f7f7f7;
	padding: 20px 200px;
	width: 100%;
	min-height: 100vh;
}
.wizardArea {
	background-color: #fff;
	padding: 20px 10px 10px;
	max-width: 1450px;
	margin: 0 auto;
	border-radius: 8px;
	min-height: calc(100vh - 140px);
	position: relative;
}
.timeLineArea {
	width: 100%;
	margin: 24px auto 0px;
}
.timeLineContainer {
	width: 80%;
	margin: 0 auto;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}
.timeLineItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: fit-content;
	gap: 10px;
	position: relative;
	z-index: 4;
	text-align: center;
	flex: 1;
}
.circleBx {
	width: 36px;
	height: 36px;
	border-radius: 100px;
	background-color: #e2e2e2;
	display: block;
	margin: 0 auto;
	position: relative;
	z-index: 9;
	display: flex;
	align-items: center;
	justify-content: center;
}
.timeLineItem:before {
	content: "";
	width: 50%;
	height: 1px;
	border-top: 1px dotted #0084ff;
	position: absolute;
	left: 0%;
	top: 18px;
	z-index: 1;
}
.timeLineItem::after {
	content: "";
	width: 50%;
	height: 1px;
	border-top: 1px dotted #0084ff;
	position: absolute;
	right: 0%;
	top: 18px;
	z-index: 1;
}
.timeLineItem:first-child:before {
	display: none;
}
.timeLineItem:last-child:after {
	display: none;
}
.timeLineText {
	font-size: 12px;
	color: #717171;
	font-weight: 600;
}
.mdTitle {
	font-size: 22px;
	font-weight: 700;
	line-height: 30px;
	color: #1b1b1b;
	text-align: center;
}
.circleDot {
	width: 8px;
	height: 8px;
	background-color: #0084ff;
	border-radius: 100%;
	position: absolute;
	display: none;
}
.timeLineItem.itemFocus .circleBx {
	background-color: #d5ebff;
}
.timeLineItem.itemFocus .circleBx .circleDot {
	display: block;
}
.checkSvg {
	position: absolute;
	width: 16px;
	height: 11px;
	object-fit: contain;
	object-position: center;
	display: none;
}
.timeLineItem.itemCompleted .circleBx {
	background-color: #0084ff;
}
.timeLineItem.itemCompleted .circleBx .checkSvg {
	display: block;
}
.timeLineItem.item-focus.item-completed .circleBx {
	background-color: #0084ff;
}
.timeLineItem.item-focus.item-completed .circleBx .checkSvg {
	display: block;
}
.timeLineItem.itemFocus .timeLineText,
.timeLineItem.itemFocus.itemCompleted .timeLineText,
.timeLineItem.itemCompleted .timeLineText {
	color: #0084ff;
}
.wizardLogo {
	width: 250px;
	height: 60px;
	object-fit: contain;
	display: block;
	margin: 0px auto 15px;
}

.wizardBtnGrp {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 10px;
}
button.btn {
	font-weight: 400;
}
.btn.mdBtn {
	width: 150px;
	height: 44px;
}
.buttonBdrBtn {
	border: 1.3px solid #c8c8c8;
	border-radius: 4px;
}
.btn.primaryBtn {
	background-color: #0084ff;
	color: #fff;
}
.btn.primaryBtn:hover,
.btn.primaryBtn:focus,
.btn.primaryBtn:active {
	background-color: #0084ff !important;
	color: #fff !important;
}
.wizardStepContent.setupNone {
	display: none;
}
.wizardStepContent.setupActive {
	display: block;
}
.btn.primaryLightBtn {
	width: fit-content;
	padding: 0px 20px;
	background-color: #c1e1ff;
	color: #0084ff;
	height: 48px;
}
.h4MdText {
	font-size: 20px;
	font-weight: 700;
}
.wdMax80Pr {
	max-width: 80%;
}
.lgBtn {
	width: 240px;
	height: 48px;
	font-size: 16px;
}
.mdText {
	font-size: 17px;
	font-weight: 600;
	color: #212529;
}
.wizardArea .inputField {
	border: 1px solid #c8c8c8;
}
.wizard-area .inputField::placeholder {
	color: #626262;
	opacity: 0.5;
}
.videoFrame {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	cursor: pointer;
}
.wizardAreaBox {
	width: 100%;
	max-width: 850px;
	margin: 0 auto;
	background-color: #fff;
	padding: 40px 0px 70px;
	border-radius: 30px;
	position: relative;
}
.btnCardBox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 500px;
	margin: 0 auto;
	padding: 50px 0px;
	border: 1px solid #c8c8c8;
	border-radius: 10px;
	margin-top: 40px;
}
.orText {
	color: #626262;
	font-size: 14px;
	font-weight: 400;
}
.pText {
	color: #212529;
	font-size: 16px;
	font-weight: 400;
}
.circleCrossBtn {
	background-color: transparent;
	border: none;
}
.circleCrossIcon {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 26px;
	right: 26px;
}
.btnCardBox .primaryBtn {
	width: 250px;
}
.wizardAreaBox .btnCardBox .primaryBtn.skipBtn {
	background-color: #ddefff !important;
	color: #0084ff !important;
}

.wizardContentWrapper {
	padding: 0px;
	max-height: calc(100vh - 370px);
	overflow-y: auto;
	min-height: calc(100vh - 370px);
}
.wizardContentWrapper::-webkit-scrollbar {
	width: 8px;
}
.wizardContentWrapper::-webkit-scrollbar-track {
	background: #ffffff;
}

.wizardContentWrapper::-webkit-scrollbar-thumb {
	background: #e2e2e2;
}
.wizardArea .footerBtnBar {
	position: absolute;
	bottom: 18px;
	left: 0px;
	right: 0px;
	width: 100%;
}
@media (max-width: 1400px) {
	.wizardSection {
		padding: 20px 100px;
	}
}
@media (max-width: 768px) {
	.wizardLogo {
		width: 240px;
		height: 60px;
	}
	.wizardSection {
		padding: 10px 10px;
	}
	.timeLineContainer {
		width: 100%;
	}
	.timeLineText {
		display: none;
	}
	.circleBx {
		width: 28px;
		height: 28px;
	}
	.checkSvg {
		width: 13px;
		height: 8px;
	}
	.circleDot {
		width: 6px;
		height: 6px;
	}
	.timeLineItem:after {
		top: 14px;
	}
	.timeLineItem:before {
		top: 14px;
	}
	.wizardContentWrapper .pageCard {
		padding: 0px 15px;
	}
	.wizardBtnGrp {
		flex-direction: column;
		gap: 15px;
		padding: 10px 5px;
	}
	.wizardBtnGrp .btn.mdBtn {
		width: 100%;
		height: 40px;
	}
	.wizardBtnGrp > div,
	.wizardBtnGrp .primaryLightBtn {
		width: 100%;
	}
	.wizardBtnGrp .d-flex.gap-3 {
		justify-content: center;
	}
	.timeLineArea {
		margin: 24px auto 20px;
	}
	.videoFrameCol {
		padding: 10px 0px;
	}
}
