.modalCardBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalCardBox h5 {
  margin: 0px;
  color: var(--dark-gray-1);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}
.modalCardBox p {
  margin: 0px;
  color: var(--dark-gray-1);
  font-size: 12px;
  font-weight: 400;
}
.modalCardBox .bdrBtn {
  background-color: unset;
  border: 1.3px solid #c8c8c8;
  font-size: 16px;
  height: 40px;
  width: 150px;
  border-radius: 4px;
  font-weight: 500;
  color: #1b1b1b;
}
.modalCardBox .fillPrimarBtn {
  background-color: var(--primary);
  color: white;
  font-size: 16px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  width: 150px;
  height: 40px;
  display: block;
}
.frmModalArea.smModal.frmModalArea .modal-dialog {
  max-width: 580px !important;
}

.mdlTextArea {
  min-height: 120px;
  width: 100%;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  outline: none !important;
}
.frmModalArea.smModal.frmModalArea .modalCardBox.mdlAlignLeft {
  align-items: flex-start !important;
}
.frmModalArea.smModal.frmModalArea .rtBtnsFlex {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.frmModalArea.smModal.frmModalArea .rtBtnsFlex .cancelBtn {
  background-color: unset !important;
  color: #1b1b1b !important;
}
.frmModalArea.smModal.frmModalArea .mdlErrorIcon {
  margin: unset;
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}
.frmModalArea.smModal.frmModalArea .modal-dialog .btn-close {
  display: block;
}
.frmModalArea.smModal.changePlanModal .modalCardBox h5 {
  text-align: center !important;
}
.frmModalArea.smModal.changePlanModal .rtBtnsFlex .cancelBtn {
  justify-content: center !important;
}
.frmModalArea.smModal.changePlanModal .modalCardBox p {
  text-align: center !important;
}
.mdlFtbtns {
  width: 100%;
}
.mdlTitle {
  text-align: left !important;
  font-weight: 18px;
  font-weight: 700;
  color: #212529;
}
.mdlFormBox {
  width: 100% !important;
  display: block !important;
}
.mdlPtext {
  color: #212529;
  font-size: 12px;
}
.inputFieldBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.inputFieldBar label {
  font-size: 15px;
  color: #212529;
  font-weight: 600;
  display: block;
}
.StripeElement {
  margin-top: 10px !important;
}

.checkTitle {
  font-size: 14px;
  font-weight: 600;
  color: #212529;
  margin-bottom: 2px;
  padding-top: 4px;
}
.form-check-label {
  cursor: pointer !important;
}
.frmModalArea .form-check-input {
  box-shadow: unset !important;
}
.inputFieldBar .StripeElement {
  height: 44px !important;
  border: 1px solid #cfd4d9;
  font-size: 14px;
  box-shadow: unset !important;
  display: flex !important;
  align-items: center !important;
  padding: 0px 15px !important;
  border-radius: 5px !important;
}
.inputFieldBar input {
  border-radius: 5px !important;
  outline: none;
  padding: 0px 15px;
}
.inputFieldBar .StripeElement > div {
  width: 100% !important;
}
.inputFieldBar .labeltextSc {
  margin-bottom: 10px !important;
}
.mdlFtbtns .saveBtn {
  width: 130px;
  height: 46px;
}
.inputField::-ms-input-placeholder {
  color: #626262 !important;
  font-weight: 400 !important;
}
.inputField::placeholder {
  color: #626262 !important;
  font-weight: 400 !important;
}
