.pageCard {
	background-color: #fff;
	width: 100%;
	border-radius: 10px;
}
.flexTitleBox {
	display: flex;
	align-items: flex-start;
	gap: 15px;
}
.flexTitleBox .backIcon {
	width: 32px;
	height: 32px;
	object-fit: contain;
	position: relative;
	top: 3px;
	cursor: pointer;
}
.tblSearchBar {
	position: relative;
}
.tblHeaderArea {
	display: flex;
	width: 100%;
	margin-bottom: 30px;
	margin-top: 10px;
}
.searchIcon {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 20px;
	height: 20px;
	object-fit: contain;
}
.tblSearchBar .searchField {
	width: 100%;
	max-width: 210px;
	min-width: 210px;
	height: 40px;
	border: 1.5px solid #c8c8c8;
	border-radius: 5px;
	padding-left: 35px;
	padding-right: 10px;
	outline: none;
}
.form-select.selectInput {
	height: 40px;
	border: 1.5px solid #c8c8c8;
	border-radius: 5px;
	min-width: 150px;
	color: #626262;
	font-size: 14px;
}
.tblRightClm {
	display: flex;
	align-items: center;
	gap: 10px;
}
.tblHeaderArea .tblRightClm {
	width: 470px;
}
.tblHeaderArea .tblLeftClm {
	width: 100%;
}
.TitleBox h4 {
	color: #212529;
	font-weight: 700;
	font-size: 20px;
}
.TitleBox p {
	font-size: 12px;
	color: #212529;
	font-weight: 400;
}
.tblSearchBar .searchField::-ms-input-placeholder {
	/* Edge 12-18 */
	color: #626262;
}
.tableScrollArea.costingTableArea .tableArea thead tr th,
.tableScrollArea.costingTableArea .tableArea body tr td {
	min-width: 170px;
}

.tblSearchBar .searchField::placeholder {
	color: #626262;
}
.tableScrollArea.costingTableArea table th,
.tableScrollArea.costingTableArea table td {
	border-right: 1px solid #e3e4e4;
}
.tableScrollArea.costingTableArea table th .tblText,
.tableScrollArea.costingTableArea table td .tblText {
	white-space: nowrap;
	padding: 0px 5px;
	text-transform: capitalize;
}
.tableScrollArea.costingTableArea table td {
	height: 60px;
}
.tableScrollArea.costingTableArea table td .tblText {
	font-weight: 400;
	color: #626262;
}
.tableScrollArea.costingTableArea .tableArea tbody td:nth-child(3),
.tableScrollArea.costingTableArea .tableArea tbody td:nth-child(4) {
	background-color: #f6f6f6;
	border-left: 1px solid #f6f6f6;
	border-right: 1px solid #e3e4e4;
}

.tableScrollArea.costingTableArea .tableArea thead th:first-child,
.tableScrollArea.costingTableArea .tableArea tbody td:first-child {
	position: sticky;
	left: 0px;
	z-index: 2;
	padding: 0px 10px;
	max-width: 250px;
	min-width: 250px;
}
.tableScrollArea.costingTableArea .tableArea thead th:nth-child(2),
.tableScrollArea.costingTableArea .tableArea tbody td:nth-child(2) {
	position: sticky;
	left: 250px;
	z-index: 2;
	padding: 0px 10px;
}
.tableScrollArea.costingTableArea table th:first-child .tblText,
.tableScrollArea.costingTableArea table td:first-child .tblText {
	max-width: 240px;
	white-space: wrap;
}
.tableScrollArea.costingTableArea .tableArea thead tr th:last-child {
	width: auto;
}
.cmnyBtn {
	width: fit-content;
	height: 40px;
	border: 0px;
	background-color: #f6f6f6;
	border-radius: 5px;
	padding: 0px 15px;
	font-size: 16px;
	font-weight: 600;
	color: #212529;
	text-decoration: none;
	display: flex;
	align-items: center;
}
.costingBoxArea > p {
	font-size: 12px;
	color: #626262;
	font-weight: 400;
	margin-bottom: 20px;
}
.btnsGirdList {
	display: flex;
	gap: 10px;
	flex-flow: wrap;
	margin-bottom: 20px;
}
.tblText.inputTblText {
	width: 100%;
	height: 60px;
	background-color: #fff;
	border: 0px;
	outline: none;
	color: var(--dark-gray-1) !important;
	font-weight: 500 !important;
}
.tblText.inputTblText.bgGray {
	background-color: var(--light-gray-1) !important;
	border-right: 1px solid #e3e4e4;
}
.tblText.inputTblText:focus {
	background-color: #fff;
}

.loadMoreBtn {
	background-color: unset;
	border: 0px;
	color: #626262;
	font-size: 15px;
	position: relative;
	top: 8px;
	margin: 8px 0px;
}

.tableScrollArea.costingTableArea.verticalScroll {
	height: 100%;
	overflow-y: auto;
	max-height: calc(100vh - 390px);
}
.tableScrollArea.costingTableArea.verticalScroll .tableArea thead {
	position: sticky;
	top: 0px;
	left: 0px;
	z-index: 9999;
}
.tblRightClm .tblSelectInput {
	position: relative;
	z-index: 99999;
}

.tblSelectInput > div {
	width: 180px !important;
	height: 40px;
	position: relative;
	top: 2px;
	border: 1.5px solid #c8c8c8 !important;
}
.tblSelectInput > div > div {
	background-color: #fff;
	max-height: 280px;
}
.tblSelectInput > div > div > div {
	font-size: 14px;
	font-weight: 400;
}
.tblHeaderArea.frmActionHead {
	justify-content: space-between;
}
.tblHeaderArea.frmActionHead .tblLeftClm {
	max-width: 250px;
}
.tblHeaderArea.frmActionHead .tblRightClm {
	width: fit-content;
}
.bdrClrLightGreen td:first-child {
	border-left: 1px solid rgb(10, 204, 10) !important;
}
.bdrTopLightGreen td {
	border-top: 1px solid rgb(10, 204, 10) !important;
}
.bdrClrLightGreen td:last-child {
	border-right: 1px solid rgb(10, 204, 10) !important;
}
.bdrBottomLightGreen td {
	border-bottom: 1px solid rgb(10, 204, 10) !important;
}

.bdrClrDarkGreen td:first-child {
	border-left: 1px solid rgb(0, 71, 0) !important;
}
.bdrClrDarkGreen td:last-child {
	border-right: 1px solid rgb(0, 71, 0) !important;
}
.bdrTopDarkGreen td {
	border-top: 1px solid rgb(0, 71, 0) !important;
}
.bdrBottomDarkGreen td {
	border-bottom: 1px solid rgb(0, 71, 0) !important;
}
.tblTd.thirdTdSticky,
.tblTh.thirdTdSticky {
	position: sticky;
	left: 413px;
	z-index: 1;
}
.filterSelect div:nth-child(4) > div > div {
	border-bottom: 1px solid #e2e2e2;
}
.filterSelect div:nth-child(4) > div > div:last-child {
	border-bottom: 0px;
}
.multiSelectItem {
	text-decoration: none;
	font-size: 12px;
	color: rgb(67, 63, 63);
}
.multiSelectItem.trashedItem {
	background-color: rgba(172, 172, 172, 0.164);
	border-color: rgb(203, 203, 203);
}

.multiSelectItem.trashedItem > span{
	color: rgb(67, 63, 63);
}
