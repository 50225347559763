.uploadImgBox .upLoadBtn {
  padding: 0px;
  background-color: unset;
  border: 0px;
  box-shadow: unset;
  position: absolute;
  right: 4px;
  bottom: 2px;
}

.uploadImgBox .upLoadBtn:focus,
.uploadImgBox .upLoadBtn:active {
  box-shadow: unset;
}

.userimgBox {
  position: relative;
}

.userimgBox .ImgEditIcon {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.frmModalArea .modal-dialog {
  max-width: 700px;
}

.frmModalArea .modal-dialog .modal-content {
  border-radius: 20px;
  padding: 20px;
}

.h5Title {
  font-size: 18px;
  font-weight: 700;
  color: var(--dark-gray-1);
}

.mdlTextMaxWd {
  max-width: 420px;
}

.frmModalArea .modal-dialog .modal-content .modal-header {
  padding: 0px;
  border: 0px;
}

.form-check.checkBoxbar .form-check-input:checked[type="checkbox"] {
  background-image: url("assets/images/icons/check-icon.svg");
  background-size: 9px;
}

.dropFileBox {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.uploadIcon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}

.input-group.fileUpload #uploadFile {
  display: none;
}

.input-group.fileUpload .input-group-text {
  display: block;
  margin: 0 auto;
  width: 240px;
  height: 44px;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 4px !important;
  color: var(--white);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mdlUploadArea {
  width: 100%;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.uploadShowImgBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.uploadUserImg {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}

.uploadShowImgBox .deleteImgBtn {
  background-color: unset;
  border: 0px;
  color: #ff4141;
  text-decoration: underline;
  font-size: 15px;
}

.fillPrimarBtn {
  background-color: var(--primary);
  color: white;
  font-size: 16px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  width: 150px;
  height: 40px;
  display: block;
}

.fillPrimarBtn.mrLauto {
  margin-left: auto;
}

.UploadImgModalArea .fillPrimarBtn.mrLauto {
  width: 240px;
  height: 48px;
}

.uploadBoxImg {
  width: 300px;
  height: 300px;
  object-fit: contain;
  border: 1px solid #c8c8c8;
  padding: 10px;
  background-color: #fff;
  margin: 0 auto 20px;
  display: block;
}

.dropFileBox.dragover {
  border-color: #2196f3;
  background-color: rgba(33, 150, 243, 0.1);
}