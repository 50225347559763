.mdRadiolBtn {
	background-color: var(--primary);
	color: var(--white);
	font-size: 12px;
	width: fit-content;
	width: fit-content;
	border: 1px solid var(--primary);
	border-radius: 100px;
	padding: 2px 20px;
	margin: 0 auto;
	display: block;
}
.chooseBtn {
	background-color: unset;
	border: 0px;
	color: var(--primary);
	font-size: 14px;
	text-decoration: underline;
	display: block;
	margin: 0 auto;
}

.monthText {
	font-size: 12px;
	color: #5d5d5d;
	font-weight: 500;
	margin-bottom: 10px;
}

.priceCard {
	padding: 15px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	gap: 30px;
}
.priceProgressBar.progress {
	height: 3px;
	background-color: #eaeaea !important;
}
.priceProgressBar .progress-bar {
	background-color: #0084ff !important;
}
.prCardTitleBar {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.cardFtPannel {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 0px 5px;
	border-top: 1px dashed #bbbbbb;
}
.cardFtPannelItem {
	display: flex;
	align-items: center;
	gap: 5px;
}
.cardFtPannelItem .pXSText {
	font-size: 13px;
	color: #1b1b1b;
}

.xsTextNbr {
	color: #1b1b1b;
	font-size: 13px;
	font-weight: 700;
}
.cardFtPannelItem span {
	color: #1b1b1b;
	font-size: 13px;
}
.innvoiceTopBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.innvoiceTopBar p {
	color: #1b1b1b;
	color: 16px;
	font-weight: 400;
}
.innvoiceTopBar span {
	font-size: 12px;
	color: #1b1b1b;
	font-weight: 500;
}
.priceTextBar.dashedBdr {
	padding-bottom: 30px;
	border-bottom: 1px dashed #a4a4a4;
	margin-bottom: 20px !important;
}
.crdTabel {
	width: 100%;
	table-layout: fixed;
}

.crdTabel thead tr th {
	background-color: #ebebeb;
	padding: 4px 5px;
	text-align: center;
}
.crdTabel thead tr th p {
	text-align: center;
}
.crdTabel thead tr th:first-child {
	border-radius: 5px 0px 0px 5px;
	padding-left: 15px !important;
	text-align: left;
}
.crdTabel thead tr th:first-child p {
	text-align: left;
}
.crdTabel thead tr th:last-child {
	border-radius: 0px 5px 5px 0px;
	padding-right: 80px !important;
}
.crdTabel thead tr th:last-child p {
	text-align: right;
}
.crdTabel tbody tr td:first-child {
	padding-left: 15px !important;
}
.crdTabel tbody tr td:first-child p {
	text-align: left;
}
.crdTabel tbody tr td:last-child {
	padding-right: 80px !important;
}
.crdTabel tbody tr td {
	padding: 12px 5px;
	text-align: center;
}
.crdTabel tbody tr td p {
	text-align: center;
	font-weight: 400;
}
.priceTblArea {
	width: 100%;
}
.crdTabel tbody tr td p.tblText.bold {
	font-weight: 700 !important;
}
.trDashedBdr {
	border-bottom: 1px dashed #a4a4a4;
}
.crdTabel tbody tr {
	vertical-align: middle;
}
.crdTabel tbody tr:first-child {
	vertical-align: top;
}
.tblText.rateText {
	font-size: 36px;
	font-weight: 400;
}
.crdTabel tbody tr td:last-child p {
	text-align: right;
	font-weight: 400 !important;
}
.lastTrBdr {
	border-top: 1px dashed #a4a4a4;
}
.tagsBarFlex {
	display: flex;
	align-items: center;
	gap: 10px;
}
.activeTagBtn {
	color: var(--white);
	font-size: 14px;
	font-size: 12px;
	width: 60px;
	height: 20px;
	border-radius: 5px;
	background-color: #0084ff;
	text-align: center;
	justify-content: center;
	font-weight: 400;
	line-height: 20px;
	border-radius: 100px;
}
.h4MdTitle {
	font-size: 36px;
	font-weight: 300;
	color: #1b1b1b;
}
.pannelRow {
	display: flex;
	background-color: #f6f6f6;
	padding: 20px 15px;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
}
.pannalColum {
	margin-bottom: 20px;
}
.pannelRow p {
	font-size: 17px;
	color: #212529;
	font-weight: 400;
	margin: 0px;
}
.pannelRow h3 {
	font-weight: 500;
	color: #1b1b1b;
	font-size: 24px;
	color: #1b1b1b;
	margin: 0px;
}
.pannelSmTitle {
	color: #212529;
	font-size: 15px;
	line-height: 21px;
	font-weight: 600;
	margin-bottom: 10px;
}
.progressBox {
	min-width: 60%;
}
.progressContent {
	flex: 1;
}
.spaceBetweenBar {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}
.spaceBetweenBar p {
	font-size: 14px;
	color: #212529;
	font-weight: 400;
}
.priceCard .progressBox {
	width: 250px;
	height: 250px;
	position: relative;
}
.progressBox .CircularProgressbar .CircularProgressbar-trail {
	stroke: #fff !important;
}
.progressBox .CircularProgressbar .CircularProgressbar-path {
	stroke: #0084ff;
}
.progressBox .CircularProgressbar .CircularProgressbar-text {
	fill: #0084ff;
	font-size: 16px;
	font-weight: 700;
	position: relative;
	transform: translateY(-4px);
}
.progressContent {
	position: relative;
}
.progressContent::before {
	content: "";
	height: 100%;
	width: 1px;
	border-left: 1px dashed #bbbbbb;
	position: absolute;
	left: -15px;
	top: 0px;
}
.priceCard.invoiceCard {
	padding: 15px 0px;
	border-top: 1px dashed #a4a4a4;
}
.priceCard.progressCard {
	background-color: #f6f6f6;
	border-radius: 10px;
}
.innvoiceTotalBar {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 30px;
}
.pageCard.currentUsage {
	padding: 40px 30px;
}
.textboxBar {
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 10px;
}
.textboxBar .billingBtn.reTryBtn {
	padding: 0px 40px;
}
.textboxBar p span {
	font-weight: 600;
}
.activeTagBtn.bg-danger {
	background: #f92525 !important;
}
