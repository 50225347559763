.mobSidebarModal.offcanvas {
  width: calc(100% - 44px);
}

.menuToggleBtn {
  position: absolute;
  left: 12px;
  top: 20px;
  width: 37px;
  padding: 6px !important;
  height: 37px;
  border-radius: 10px;
  box-shadow: unset;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.menuToggleBtn {
  background-color: var(--primary);
  border-color: var(--primary);
}

.mobSidebarModal .btn-close {
  width: 36px;
  height: 36px;
  background-color: var(--primary);
  padding: 0px;
  background-image: url("assets/images/icons/mdl-cross-icon.svg");
  border-color: var(--primary);
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  position: absolute;
  right: -44px;
  top: 20px;
}

.MobUserImg {
  width: 54px;
  height: 54px;
  border-radius: 100px;
  background-color: #f4f4f4;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobUserBox {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--primary);
  padding: 15px 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.mobUserBox h5 {
  color: var(--white);
  font-size: 15px;
  font-weight: 800;
}

.mobUserBox p {
  color: var(--white);
  font-size: 12px;
}

.mobSideManuArea.offcanvas-body .accordion-item {
  border: 0px;
  background-color: #f4f4f4;
  position: relative;
  margin-bottom: 5px;
  border-radius: 8px;
}

.mobSideManuArea.offcanvas-body .accordion-item .accordion-button {
  background-color: unset;
  position: relative;
  padding-left: 15px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  border: 0;
  box-shadow: unset;
}

.mobSideManuArea.offcanvas-body .accordion-item .accordion-button h5 {
  font-size: 15px;
  font-weight: 600;
  color: var(--dark-gray-1);
}

.offcanvas-backdrop {
  background-color: rgb(0 0 0 / 50%);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}

.mobSideManuArea.offcanvas-body .accordion-item:first-child .accordion-button:after {
  background-image: unset;
}

.mobSideManuArea.offcanvas-body .accordion-item .accordion-button:after {
  position: absolute;
  right: 10px;
  background-image: url("assets/images/icons/collapse-arrow-icon.svg");
  transform: rotate(-90deg);
  background-size: 14px;
  top: 15px;
  background-position: center;
}

.mobSideManuArea.offcanvas-body .accordion-item [aria-expanded="true"].accordion-button:after {
  transform: rotate(0deg) !important;
}

.mobManuList {
  margin: 0px;
  list-style-type: none;
}

.mobManuList .mobManItem {
  font-size: 14px;
  color: var(--dark-gray-1);
  text-decoration: none;
  font-weight: 500;
  position: relative;
  display: block;
  margin-bottom: 7px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-radius: 5px;
}

.mobManuList .mobManItem:hover {
  background-color: #e8e8e8;
}

.mobManuList .mobManItem.active {
  background-color: #e8e8e8;
  color: var(--primary);
}

.mobManuList .mobManItem.active:before {
  background-color: var(--primary);
}

.mobManuList .mobManItem:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background-color: #212529;
  position: relative;
  left: -10px;
  top: 0;
}

.mobSideManuArea .mobManuListArea.accordion-body {
  padding: 0px 20px 12px 22px;
}

.mobSideManuArea.offcanvas-body {
  position: relative;
}

.mobLogoutBtn {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  border: 0px;
  height: 50px;
  border-radius: 8px;
  justify-content: flex-start;
  padding: 0px 15px;
  gap: 8px;
  font-size: 16px;
  color: #8b8b8b;
  font-weight: 600;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: calc(100% - 30px);
  margin: 15px;
  margin-bottom: 30px;
}

.logOutIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.mobSidebarModal.offcanvas.offcanvas-start {
  width: 100%;
  max-width: 290px;
}