.pagination ul {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}
.pagination ul li a {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border-radius: 100px;
  text-decoration: none !important;
  color: #212529;
  font-size: 14px;
  font-weight: 600;
}
.pagination ul li.selected a {
  background-color: var(--primary);
  color: #fff;
}
.pagination ul li.selected a:hover {
  color: #fff;
}
.pagination ul li:first-child a {
  background-image: url("assets/images/icons/left-arrow-icon.svg");
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: center;
}
.pagination ul li:last-child a {
  background-image: url("assets/images/icons/right-arrow-icon.svg");
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: center;
}
.emptyBox img {
  width: 54px;
  object-fit: contain;
  margin-bottom: 10px;
}
.emptyBox p {
  font-size: 14px;
  font-weight: 700;
  color: #212529;
}
.tblText.linkText {
  color: var(--primary);
  text-decoration: underline;
}
