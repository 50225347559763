.pageCard {
	background-color: #fff;
	width: 100%;
	border-radius: 10px;
}
.flexTitleBox {
	display: flex;
	align-items: flex-start;
	gap: 15px;
}
.flexTitleBox .backIcon {
	width: 32px;
	height: 32px;
	object-fit: contain;
	position: relative;
	top: 3px;
	cursor: pointer;
}
.tblSearchBar {
	position: relative;
}
.tblHeaderArea {
	display: flex;
	width: 100%;
	margin-bottom: 30px;
	margin-top: 10px;
}
.searchIcon {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 20px;
	height: 20px;
	object-fit: contain;
}
.tblSearchBar .searchField {
	width: 100%;
	max-width: 210px;
	min-width: 210px;
	height: 40px;
	border: 1.5px solid #c8c8c8;
	border-radius: 5px;
	padding-left: 35px;
	padding-right: 10px;
	outline: none;
}
.form-select.selectInput {
	height: 40px;
	border: 1.5px solid #c8c8c8;
	border-radius: 5px;
	min-width: 150px;
	color: #626262;
	font-size: 14px;
}
.tblRightClm {
	display: flex;
	align-items: center;
	gap: 10px;
}
.tblHeaderArea .tblRightClm {
	width: 370px;
}
.tblHeaderArea .tblLeftClm {
	width: 100%;
}
.TitleBox h4 {
	color: #212529;
	font-weight: 700;
	font-size: 20px;
}
.TitleBox p {
	font-size: 12px;
	color: #212529;
	font-weight: 400;
}
.tblSearchBar .searchField::-ms-input-placeholder {
	/* Edge 12-18 */
	color: #626262;
}

.tblSearchBar .searchField::placeholder {
	color: #626262;
}
.tableScrollArea.programTableArea table th,
.tableScrollArea.programTableArea table td {
	border-right: 1px solid #e3e4e4;
}
.tableScrollArea.programTableArea table th .tblText,
.tableScrollArea.programTableArea table td .tblText {
	white-space: nowrap;
	padding: 0px 5px;
}
.tableScrollArea.programTableArea table td {
	height: 60px;
}
.tableScrollArea.programTableArea table td .tblText {
	font-weight: 400;
	color: #626262;
}
.tableScrollArea.programTableArea table td .tblText img {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.tableScrollArea.programTableArea table td:nth-child(3),
.tableScrollArea.programTableArea table td:nth-child(4) {
	background-color: #e6e6e6;
	border-left: 1px solid #d8d8d8;
	border-right: 1px solid #d8d8d8;
}
.tableScrollArea.programTableArea table td:nth-child(3) .tblText,
.tableScrollArea.programTableArea table td:nth-child(4) .tblText {
	color: var(--dark-gray-1);
	font-weight: 500;
}
.tableScrollArea.programTableArea table th:first-child,
.tableScrollArea.programTableArea table td:first-child {
	position: sticky;
	left: 0px;
	z-index: 2;
	padding: 0px 10px;
}
.tableScrollArea.programTableArea .tableArea thead tr th:last-child {
	width: auto;
}
.tableScrollArea.programTableArea .tableArea thead tr th:last-child p {
	text-align: center;
}
.cmnyBtn {
	width: fit-content;
	height: 40px;
	border: 0px;
	background-color: #f6f6f6;
	border-radius: 5px;
	padding: 0px 15px;
	font-size: 16px;
	font-weight: 600;
	color: #212529;
}
.costingBoxArea > p {
	font-size: 12px;
	color: #626262;
	font-weight: 400;
	margin-bottom: 20px;
}
.btnsGirdList {
	display: flex;
	gap: 10px;
	flex-flow: wrap;
	margin-bottom: 20px;
}
.programTableArea .tblText.inputTblText {
	width: 100%;
	height: 60px;
	background-color: #fff;
	border: 0px;
	outline: none;
}
.tblText.inputTblText:focus {
	background-color: #fff;
}
.tblRightClm.flexEnd {
	justify-content: flex-end;
}

.frmModalArea.maxWd500 .modal-dialog .modal-content {
	max-width: 500px !important;
	margin: 0 auto !important;
}

.tableScrollArea.programTableArea.cellMaxWd .tableArea thead tr th:first-child .tblText {
	text-align: center;
	max-width: 100% !important;
}
.tableScrollArea.programTableArea.cellMaxWd table tbody td:first-child .tblText {
	text-align: center;
	max-width: 100% !important;
}
.tableScrollArea.programTableArea.cellMaxWd .tableArea {
	max-width: 50% !important;
	min-width: unset;
}
.tableScrollArea.programTableArea.cellMaxWd table tbody td:first-child {
	padding: 0px !important;
}
.tableScrollArea.programTableArea.cellMaxWd .tableArea thead tr th {
	padding: 0px !important;
}
.customProgramsBox .InviteUserBtn {
	font-size: 12px;
}
.customProgramsBox .multiSelectItem.trashedItem {
	background-color: rgba(172, 172, 172, 0.164);
	border-color: rgb(203, 203, 203);
}
.customProgramsBox .multiSelectItem.trashedItem > span {
	color: rgb(67, 63, 63);
}
.externalProgramsBox .programSelect {
	margin-bottom: 10px;
	width: 50%;
}

@media (max-width: 768px) {
	.tableScrollArea.programTableArea table {
		min-width: unset;
	}
	.tableScrollArea.programTableArea table .tblTh:first-child,
	.tableScrollArea.programTableArea table .tblTd:first-child {
		width: 200px !important;
		min-width: 200px !important;
	}
	.tableScrollArea.programTableArea table .tblTh:last-child,
	.tableScrollArea.programTableArea table .tblTd:last-child {
		width: 80px !important;
		min-width: 80px !important;
	}
	.tblHeaderArea .tblRightClm {
		width: 100%;
	}
}
