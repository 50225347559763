.form-check.checkBoxbar {
  display: flex;
  gap: 5px;
  align-items: center;
}
.checkBoxbar .checkLabel {
  font-size: 12px;
  color: #838383;
  font-weight: 400;
  position: relative;
  top: 2px;
}
.form-check.checkBoxbar .circleCheck {
  width: 16px;
  height: 16px;
  border-radius: 100px;
}
.form-check.checkBoxbar .form-check-input:checked[type="checkbox"] {
  background-image: url("assets/images/icons/check-icon.svg");
  background-size: 9px;
}
.checkboxList {
  width: 100%;
  margin-bottom: 20px;
}

.checkListTtile {
  color: #1b1b1b;
  font-size: 12px;
  font-weight: 15px;
  margin-bottom: 5px;
}
.checkboxList.bgGreen .checkBoxbar .form-check-input:checked {
  background-color: #0b6b3f;
  border-color: #0b6b3f;
}
