p,
h6,
h5,
h4,
h3,
h2,
h1,
a,
span,
button,
input,
label,
textarea,
ul,
li {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif !important;
}
.pageCard {
  padding: 20px;
  background-color: var(--white);
  border-radius: 10px;
}
.h4MdTitle {
  font-size: 18px;
  font-weight: 700;
  color: #1b1b1b;
}
.cardBox {
  background-color: var(--light-gray-1);
  padding: 20px;
  border-radius: 10px;
}
.smH6Text {
  font-size: 15px;
  font-weight: 700;
  color: #1b1b1b;
}
.tblUserIcon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  object-fit: cover;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 100px;
  font-weight: 700;
}
.tblFlexBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.tableArea {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.tableArea thead tr th,
.tableArea tbody tr td {
  background-color: var(--light-gray-1);
  border-top: 1px solid #e3e4e4;
  border-bottom: 1px solid #e3e4e4;
}
.tableArea thead tr th:first-child,
.tableArea tbody tr td:first-child {
  border-radius: 5px 0px 0px 5px !important;
  border-left: 1px solid #e3e4e4;
}
.tableArea thead tr th:last-child,
.tableArea tbody tr td:last-child {
  border-radius: 0px 5px 5px 0px !important;
  border-right: 1px solid #e3e4e4;
}
.tblText {
  color: var(--dark-gray-1);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
}
.tableArea thead tr {
  height: 30px;
}
.tableArea thead tr th:last-child {
  width: 270px;
}
.tableArea tbody tr td {
  height: 50px;
}
.tableSpace {
  display: block;
  width: 100%;
  height: 5px;
}
.tblLinkBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.tblLinkBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.tblLinkBar .linkText {
  font-size: 14px;
  color: var(--primary);
}
.linkText.linkClr2 {
  color: #ad3f3f;
}
.h5Title {
  font-size: 18px;
  font-weight: 700;
  color: var(--dark-gray-1);
}
.smText {
  color: var(--dark-gray-1);
  font-size: 12px;
  line-height: 16px;
}
.mdlTextMaxWd {
  max-width: 560px;
}

.formArea .SelectField {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  height: 44px;
}
.textAreaField {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
}
.textAreaField::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #1b1b1b;
}

.textAreaField::placeholder {
  color: #1b1b1b;
}
.formArea .fillPrimarBtn.InviteBtn {
  width: 200px;
  height: 44px;
}
.cardBox .InviteUserBtn {
  background-color: var(--white);
  border: 1.5px solid var(--primary);
  border-radius: 4px;
  color: var(--primary);
  font-size: 15px;
  width: fit-content;
  padding: 0px 20px;
  height: 40px;
  white-space: nowrap;
}
.pageCard .cardBox .smText {
  padding-right: 20px;
}
.tableScrollAreaUser.userListViewTable table tr th:last-child p,
.tableScrollAreaUser.userListViewTable table tr th:last-child {
  text-align: right !important;
}
.tableScrollAreaUser.userListViewTable .tableArea .tblTh .tblText {
  text-align: left;
}
.tableScrollAreaUser.userListViewTable .tableArea tbody .tblTd .tblText {
  text-align: left;
}
.tableScrollAreaUser.userListViewTable .tableArea tbody .tblTd:last-child .tblText {
  text-align: right;
}
.tableScrollAreaUser.userListViewTable .tableArea tbody .tblTd .tblFlexBar {
  justify-content: flex-start;
}
.tableScrollAreaUser.userListViewTable .tableArea tbody .tblTd .tblLinkBar {
  justify-content: flex-end;
}

.tableScrollAreaUser.userListViewTable .tableArea tbody td {
	padding-right: 10px;
	overflow: hidden;
	word-break: break-all;
	padding-right: 15px;
}

.tableScrollAreaUser table {
	min-width: 800px;
	table-layout: fixed;
}

.addPlusBtn {
  background-color: unset;
  border: 0px;
  outline: none;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 30px;
}
.addPlusBtn img {
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
}
.addPlusBtn span {
  color: #0082fc;
  font-size: 14px;
  font-weight: 400;
}

.tblUserDropDown {
  width: 160px;
  position: relative;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.tblUserDropDown .dpToggleBtn {
  padding: 0px;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: unset;
  position: absolute;
  top: 1px;
  right: 0px;
}
.tblUserDropDown .dpToggleBtn:after {
  display: none;
}
.tblUserDropDown .dpToggleBtn .dpCountTag {
  min-width: 22px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 100px;
  border: 2px solid #d9d9d9;
  font-size: 11px;
  font-weight: 600;
  color: #212529;
  box-sizing: border-box;
}
.dpBtnText {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  white-space: nowrap;
  width: 120px;

  display: block;
}
.txtOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.tblUserDropDown .dpToggleBtn[aria-expanded="true"] .dpCountTag {
  background-color: #0084ff;
  border: 2px solid #0084ff;
  color: #fff;
  font-weight: 500;
}
.tblUserDropDown .dropdown-menu {
  background-color: #212529;
  border: 1px solid #212529;
  border-radius: 5px;
  padding: 5px 10px;
  width: 200px;
  right: 0px;
  margin-top: 5px;
}
.tblUserDropDown .dropdown-menu li button {
  color: #fff;
  font-size: 12px;
  padding: 0px 0px;
  display: flex;
  align-items: flex-start;
  background-color: unset;
  border: 0px;
  box-shadow: unset;
  cursor: unset;
}
.tblUserDropDown .dropdown-menu li button:hover {
  background-color: unset;
  color: #fff;
}
.tblUserDropDown .dropdown-menu li:first-child {
  border-bottom: 1px dashed #fff;
}
.dpItemTextBar {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
  align-items: center;
}
.dpItemTextBar p {
  font-size: 12px;
  color: #fff;
  margin: 0px;
  max-width: 110px;
  white-space: normal;
}
.dpItemTextBar span {
  font-size: 12px;
  color: #fff;
  margin-bottom: 5px;
}
.tblUserDropDown .dpBtnBar {
  display: flex;
  justify-self: unset;
  align-items: center;
}
.tblSelectInput {
  width: 100%;
  max-width: 300px;
  height: 44px;
  background-color: #f6f6f6;
  border-radius: 10px;
  border: 0px;
  font-size: 14px;
}
.tblSelectInput option {
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}
.form-select.tblSelectInput {
  background-image: url("assets/images/icons/down-dark-arrow.svg");
  background-size: 16px;
  margin-left: auto;
}
.dropdown-menu.dropdown-menu-end.active {
  display: block;
}

.frmModalArea.ModifyModal.modal .modal-dialog .btn-close {
  background-image: url("assets/images/icons/cross-icon.svg");
  padding: 0;
  background-size: 34px;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 17px;
  right: 18px;
}
.frmModalArea.ModifyModal .modal-dialog {
  max-width: 680px;
}
.frmModalArea.ModifyModal .modal-dialog .modal-header {
  border: 0px;
}
.frmModalArea.ModifyModal .modal-dialog .modal-content {
  padding: 20px;
  border-radius: 20px;
}
.frmModalArea.ModifyModal .modal-dialog .modal-content .modal-header {
  position: relative;
}
.frmModalArea.ModifyModal .mdlLinkBtn {
  background-color: unset !important;
  padding: 0px;
  border: 0px;
  color: #000 !important;
  text-decoration: underline;
}
.linkText.ModifyModalBtn {
  background-color: unset;
  border: 0px !important;
  text-decoration: underline;
  box-shadow: unset !important;
}
.ModifyModal .SelectField {
  background-image: url("assets/images/icons/down-dark-arrow.svg");
  background-size: 14px;
}
.ModifyModal .SelectField {
  height: 40px;
  font-size: 14px;
}
.selectFieldBar {
  margin-bottom: 30px;
}
.programsFlexBar {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
  margin-bottom: 6px;
}
.programsFieldBar {
  width: 100%;
}
.programsFieldBar .inputField {
  width: 100%;
  height: 40px;
}
.programsFieldBar.fDeleteBtn {
  width: 80px;
}
.fieldDeleteBtn {
  width: 40px;
  height: 40px;
  background: unset;
  border: 1px solid #cfd4d9;
  border-radius: 5px;
}
.modifyModalForm .labelText {
  font-size: 12px;
  color: #1b1b1b;
  font-weight: 500;
}
.programsFieldBar .inputField {
  font-size: 14px;
}

.programsFieldBar .inputField::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #626262;
  font-weight: 400;
  font-size: 14px;
}

.programsFieldBar .inputField::placeholder {
  color: #626262;
  font-weight: 400;
  font-size: 14px;
}
.addPlusBtn {
  background-color: unset;
  border: 0px;
  outline: none;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 30px;
}
.addPlusBtn img {
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
}
.addPlusBtn span {
  color: #0082fc;
  font-size: 14px;
  font-weight: 500;
}
.mdlFtBar {
  padding-top: 20px;
  width: 100%;
  border-top: 1.5px dashed #cfd4d9;
}
.tblProgramsBar {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
}
.tooltipBar {
  position: absolute;
  left: 0px;
  top: -52px;
  white-space: nowrap;
  width: fit-content;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9;
  background-color: #fff;
  border-radius: 10px;
}
.tooltipBar:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #fff;
  position: absolute;
  left: 10px;
  bottom: -12px;
}
.tblProgramsBar:hover .tooltipBar {
  visibility: visible;
  opacity: 1;
}
.tooltipBar p {
  margin: 0px;
  font-size: 12px;
  color: var(--dark-gray-1);
  font-weight: 400;
}
.formArea .smText {
  font-size: 12px;
}

.smLinkText, button.smLinkText {
  font-size: 12px;
  color: var(--primary);
}
