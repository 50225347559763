.infoColorBoxItem {
	width: 14px;
	height: 14px;
	border-radius: 2px;
	border: 1px solid #212529;
}
.infoColorBoxText {
	font-size: 14px;
	font-weight: 400;
	color: #626262;
}
