.mobHeaderArea{
display: flex;
justify-content: center;
padding: 20px;
gap: 20px;
position: relative;
}
.mobHeaderArea{
    display: none;
}
