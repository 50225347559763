.headerArea{
padding:0px 20px;
min-height: 66px;
background-color: var(--white);
}
.tileBar{
min-height: 66px;
display: flex;
align-items: center;
}
.wrappeTitle{
font-size: 18px;
color: #1B1B1B;
font-weight: 600;
}