p,
h6,
h5,
h4,
h3,
h2,
h1,
a,
span,
button,
input,
label,
textarea,
ul,
li {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif !important;
}
.invoiceModalArea.modal .modal-dialog {
  max-width: 750px;
}
.invoiceModalArea.modal .modal-dialog .modal-header {
  border: 0px;
}
.accountInfoBox {
  margin-bottom: 30px;
}
.accountInfoBox > h5 {
  font-size: 14px;
  font-weight: #212529;
  font-weight: 600;
  margin-bottom: 8px;
}
.accountInfoBox .mdlTextBar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.accountInfoBox .mdlTextBar h6 {
  font-size: 12px;
  font-weight: 700;
  color: #212529;
  margin: 0px;
}
.accountInfoBox .mdlTextBar p {
  font-size: 12px;
  margin: 0px;
}

.invoiceMdlCard .pannelSmTitle {
  color: #212529;
  font-size: 12px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 10px;
}
.progressContent {
  flex: 1;
}
.spaceBetweenBar {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.invoiceMdlCard .spaceBetweenBar p {
  font-size: 12px;
  color: #1b1b1b;
  font-weight: 500;
  margin: 0px;
}
.priceCard .progressBox {
  width: 105px;
  height: 105px;
  position: relative;
}
.invoiceMdlCard .progressContent::before {
  display: none;
}
.prCardTitleBar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.priceCard.bglight {
  background-color: #f6f6f6;
  border-radius: 5px;
}

.invoiceMdlCard .priceTextBar.dashedBdr {
  padding-bottom: 30px;
  border-bottom: 1px dashed #a4a4a4;
  margin-bottom: 20px !important;
}
.invoiceMdlCard .crdTabel {
  width: 100%;
  table-layout: fixed;
}

.invoiceMdlCard .crdTabel thead tr th {
  background-color: #ebebeb;
  padding: 4px 5px;
  text-align: center;
}
.invoiceMdlCard .crdTabel thead tr th p {
  text-align: center;
  font-size: 12px;
}
.invoiceMdlCard .crdTabel thead tr th:first-child {
  border-radius: 5px 0px 0px 5px;
  padding-left: 15px !important;
  text-align: left;
}
.invoiceMdlCard .crdTabel thead tr th:first-child p {
  text-align: left;
}
.invoiceMdlCard .crdTabel thead tr th:last-child {
  border-radius: 0px 5px 5px 0px;
  padding-right: 30px !important;
}
.invoiceMdlCard .crdTabel thead tr th:last-child p {
  text-align: right;
}
.invoiceMdlCard .crdTabel tbody tr td:first-child {
  padding-left: 15px !important;
}
.invoiceMdlCard .crdTabel tbody tr td:first-child p {
  text-align: left;
}
.invoiceMdlCard .crdTabel tbody tr td:last-child {
  padding-right: 30px !important;
}
.invoiceMdlCard .crdTabel tbody tr td {
  padding: 5px 5px;
  text-align: center;
}
.invoiceMdlCard .crdTabel tbody tr td p {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}
.invoiceMdlCard .priceTblArea {
  width: 100%;
}
.invoiceMdlCard .crdTabel tbody tr td p.tblText.bold {
  font-weight: 700 !important;
}
.invoiceMdlCard .trDashedBdr {
  border-bottom: 1px dashed #a4a4a4;
}
.invoiceMdlCard .crdTabel tbody tr {
  vertical-align: middle;
}
.invoiceMdlCard .crdTabel tbody tr:first-child {
  vertical-align: top;
}
.invoiceMdlCard .tblText.rateText {
  font-size: 18px;
  font-weight: 400;
}
.invoiceMdlCard .crdTabel tbody tr td:last-child p {
  text-align: right;
  font-weight: 700 !important;
  font-size: 12px !important;
  color: var(--black);
}
.invoiceMdlCard .innvoiceTotalBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.invoiceMdlCard .mdlTblTotalBar .mdlTblTotalTd {
  background-color: #f6f6f6;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-radius: 5px;
}

.invoiceMdlCard .priceTblArea.mdlDataTable .tblText {
  font-size: 11px !important;
  font-weight: 400 !important;
}
.invoiceMdlCard .priceTblArea.mdlDataTable .crdTabel thead tr th:first-child {
  text-align: center !important;
  padding-left: 5px !important;
  width: 60px;
}
.invoiceMdlCard
  .priceTblArea.mdlDataTable
  .crdTabel
  thead
  tr
  th:first-child
  p {
  text-align: center !important;
}
.invoiceMdlCard .priceTblArea.mdlDataTable .crdTabel tbody tr td:first-child {
  text-align: center !important;
  padding-left: 5px !important;
}
.invoiceMdlCard
  .priceTblArea.mdlDataTable
  .crdTabel
  tbody
  tr
  td:first-child
  p {
  text-align: center !important;
}
.invoiceMdlCard
  .priceTblArea.mdlDataTable
  .crdTabel
  tbody
  .crdTbltd
  .tblTextTag {
  background-color: #0084ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 8px;
  font-size: 10px;
  color: #fff;
  border-radius: 100px;
  white-space: nowrap;
  font-weight: 400;
}
.invoiceMdlCard .priceTblArea.mdlDataTable .tblTextTag {
  background-color: #0084ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 8px;
  font-size: 10px;
  color: #fff;
  border-radius: 100px;
  white-space: nowrap;
  font-weight: 400;
}
.tblText.tagFlex {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  word-break: break-all;
  min-width: 90px;
}
.invoiceMdlCard .priceTblArea.mdlDataTable .crdTabel thead tr th:last-child {
  padding-right: 5px !important;
  text-align: center !important;
}
.invoiceMdlCard .priceTblArea.mdlDataTable .crdTabel thead tr th:last-child p {
  text-align: center !important;
}
.invoiceMdlCard .priceTblArea.mdlDataTable .crdTabel tbody tr td:last-child {
  padding-right: 5px !important;
  text-align: center !important;
}
.invoiceMdlCard .priceTblArea.mdlDataTable .crdTabel tbody tr td:last-child p {
  text-align: center !important;
}
.searchBarArea {
  position: relative;
  margin-bottom: 15px;
}
.searchBarArea .searchInput {
  width: 180px;
  height: 34px;
  border-radius: 100px;
  border: 1px solid #a4a4a4;
  outline: none;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
  color: #000;
}
.searchBarArea .searchInput::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #a4a4a4;
  font-size: 14px;
  font-weight: 400;
}

.searchBarArea .searchInput::placeholder {
  color: #a4a4a4;
  font-size: 14px;
  font-weight: 400;
}
.searchBarArea .serachIcon {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
}
.modalLogo {
  width: 200px;
}
.invoiceModalArea.modal .modal-dialog .modal-content {
  padding: 20px;
  border-radius: 20px;
}
.invoiceModalArea.modal .modal-dialog .modal-content .modal-header {
  position: relative;
}
.progressContent .spaceBetweenBar .tBold {
  font-weight: 700;
  color: #000;
}
.cardFtPannel .cardFtPannelItem .tBold {
  font-weight: 700;
  color: #000;
}
.invoiceMdlCard .cardFtPannelItem .pXSText {
  font-size: 12px;
  color: #1b1b1b;
  font-weight: 500;
  margin: 0px;
}
.invoiceMdlCard .crdTabel tbody tr:nth-last-child(2) td {
  padding-bottom: 24px;
}
.invoiceMdlCard .crdTabel tbody tr:first-child td {
  padding-top: 12px !important;
}
.invoiceModalArea.modal .modal-dialog .btn-close {
  background-image: url("assets/images/icons/cross-icon.svg");
  padding: 0;
  background-size: 34px;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 12px;
  right: 18px;
}
.mdlLinkBtn {
  background-color: unset !important;
  padding: 0px;
  border: 0px;
  color: #000 !important;
  text-decoration: underline;
}
.invoiceMdlCard .crdTabel thead tr th:nth-child(4) {
  width: 60px;
}
.scrolldata {
  display: flex;
  align-items: center;
  gap: 5px;
}
.thArrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.thArrows img {
  width: 7px;
  cursor: pointer;
}
.invoiceMdlCard .mdlTblTotalBar .mdlTblTotalTd .innvoiceTotalBar p {
  font-size: 16px !important;
}
.invoiceTagBtn {
  width: fit-content;
  padding: 2px 10px !important;
  font-size: 11px;
  color: var(--white);
  background-color: #0084ff;
  border-radius: 100px;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 4px; 
}
.invoiceMdlCard .priceTblArea.mdlDataTable .tdTextLeft,
.invoiceMdlCard .priceTblArea.mdlDataTable .tdTextLeft p {
  text-align: left !important;
}
.invoiceMdlCard
  .priceTblArea.mdlDataTable
  .crdTabel
  tbody
  tr
  .tdTextLeft:last-child
  p {
  text-align: left !important;
}
.invoiceMdlCard .crdTabel tbody tr:nth-last-child(2) .tdTextLeft{
padding-bottom: 0px !important;
}