/*..signin Modal css Start...*/
.signInMoal{
  z-index: 999999 !important;
}
.signInMoal .modal-dialog {
  max-width: 600px;
}

.signInMoal .modal-dialog .modal-content {
  border-radius: 20px;
  padding: 20px 30px;
  position: relative;
}
.signInMoal .modal-dialog .modal-header {
  border: 0px;
  padding: 0px;
}
.signInMoal .modal-dialog .modal-header .btn-close {
  background-image: url("assets/images/icons/cross-icon.svg");
  margin: 0;
  position: absolute;
  right: 22px;
  top: 22px;
  width: 34px;
  height: 34px;
  background-size: 34px;
  padding: 0;
  z-index: 9;
}
.mdlTitle {
  font-size: 22px;
  color: var(--dark-gray-1);
  font-weight: 700;
  margin-bottom: 10px;
}
.mdlText {
  font-size: 12px;
  color: var(--dark-gray-1);
  line-height: 18px;
  font-weight: 400;
  max-width: 450px;
}
.mdlFrmLogo {
  width: 210px;
  object-fit: contain;
  margin-bottom: 30px;
}
.labelText {
  font-size: 14px;
  color: #212529;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
}
.inputField {
  height: 44px;
  border: 1px solid #cfd4d9;
  font-size: 14px;
  box-shadow: unset !important;
}
.inputField:focus {
  border: 1px solid #cfd4d9;
}
.inputField::-ms-input-placeholder {
  /* Edge 12-18 */
  color: var(--dark-gray-1);
  font-size: 14px;
  font-weight: 500;
}

.inputField::placeholder {
  color: var(--dark-gray-1);
  font-size: 14px;
  font-weight: 500;
}
.labelText {
  font-size: 14px;
  color: #212529;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
}
.frmMdlBtn {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 4px;
  background-color: #a0cbbd;
  width: 190px !important;
  height: 46px;
  border: 1px solid #a0cbbd;
  display: block;
  color: var(--green);
}
.frmLink {
  display: block;
  width: fit-content;
  margin-left: auto;
  font-size: 15px;
  color: #212529;
  font-weight: 600;
  text-decoration: none;
  margin-top: 4px;
  cursor: pointer;
}
.frmLink:hover {
  color: #212529;
}
.backText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  gap: 5px;
  margin-left: auto;
}
.backText .frmLink {
  margin-top: 0px;
  text-decoration: underline;
}
.frmAlert.alert-primary {
  background-color: #a0cbbd;
  color: var(--green);
  border-radius: 12px;
  margin-top: 10px;
}
.frmAlert .alert-heading {
  font-size: 14px;
  font-weight: 700;
  color: #0b6b3f;
}
.frmAlert p {
  font-size: 12px;
  font-weight: 500;
}
.frmAlert.alert-primary .btn-close {
  background-image: url("assets/images/icons/alert-close-icon.svg");
  padding: 0px;
  background-size: 22px;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 12px;
}
.signinContent .signInBtn {
  width: 240px;
  background-color: var(--white);
  height: 46px;
  color: var(--green);
  font-weight: 400;
  text-align: center;
  border: 1px solid var(--white);
  font-size: 16px;
}

.signinContent .signInBtn:hover,
.signinContent .signInBtn:focus,
.signinContent :not(.btn-check) + .btn:active {
  color: var(--green);
  background-color: var(--white);
  border: 1px solid var(--white);
}
