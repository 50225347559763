.sidebarArea {
	width: 59px;
	min-width: 59px;
	height: 100vh;
	background-color: white;
	padding-top: 10px;
	box-shadow: 1px 0px 2px #00000021;
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 9;
}
.sidebarLogoLink img {
	width: 48px;
	display: block;
	margin: 0 auto;
	object-fit: contain;
	object-position: 1px 0px;
}
.navList {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 11px;
	gap: 9px;
	overflow-y: auto;
	max-height: 66vh;
}

.navList::-webkit-scrollbar {
	width: 5px;
}
.navList::-webkit-scrollbar-track {
	background: #ffffff;
}
.navList::-webkit-scrollbar-thumb {
	background: #e0e0e0;
}
.navListItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
}
.navListArea .sidebarLogoLink {
	display: block;
	margin-bottom: 5px;
}
.navListItem span {
	font-size: 10px;
	font-weight: 500;
	margin: 0;
}

.navList .navLink {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer !important;
	border-radius: 9px;
}
.navList .navLink svg,
.navItem .navLink svg {
	font-size: 23px;
	height: 23px;
	object-fit: contain;
	object-position: center;
}
.navList .navLink svg path,
.navItem .navLink svg path {
	color: #666666;
}
.navList .navLink.active {
	background-color: #ecf6ff;
}
.navList .navLink.disabled {
	opacity: 0.2;
}
.navList .navLink.disabled svg path {
	font-size: 25px;
}
.navList .navLink.active svg path,
.navList .navLink.active svg rect {
	color: var(--primary);
}
.navItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	padding-top: 10px;
	cursor: pointer;
}
.navItem .navListItem:last-child span:nth-child(2) {
	margin-top: 3px;
}
.navRow {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-bottom: 40px;
}
.disabled {
	cursor: default;
	color: #dddddd;
}
