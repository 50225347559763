.formContainer {
	padding: 20px;
	padding-left: 80px;
	height: 100vh;
}
.logoBox {
	margin-bottom: 30px;
}
.frmLogo {
	width: 250px;
}
.mdTitle {
	font-size: 30px;
	line-height: 30px;
	font-weight: 700;
}
.upperCase {
	text-transform: uppercase;
}
.smText {
	color: var(--dark-gray-1);
	font-size: 12px;
	line-height: 16px;
}
.formContentArea {
	width: 100%;
	position: relative;
}
.formContentArea .smText {
	max-width: 500px;
}
.labelText {
	font-size: 14px;
	color: #212529;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 6px;
}
.inputField {
	height: 44px;
	border: 1px solid #cfd4d9;
	font-size: 14px;
	box-shadow: unset !important;
}
.inputField:focus {
	border: 1px solid #cfd4d9;
}
.inputField::-ms-input-placeholder {
	/* Edge 12-18 */
	color: var(--dark-gray-1);
	font-size: 14px;
	font-weight: 500;
}

.inputField::placeholder {
	color: var(--dark-gray-1);
	font-size: 14px;
	font-weight: 500;
}
.dashedLine {
	margin-bottom: 20px;
}
.submitBtn {
	font-size: 16px;
	color: #0b6b3f;
	font-weight: 500;
	text-align: center;
	border-radius: 4px;
	background-color: #a0cbbd;
	width: 240px;
	height: 46px;
	border: 1px solid #a0cbbd;
	display: block;
}
.mrLauto {
	margin-left: auto;
}
.mdH5 {
	font-size: 16px;
	color: var(--dark-gray-1);
	font-weight: 700;
}
.tabsList.nav-tabs {
	border: 0px;
	gap: 20px;
}
.tabsList .nav-item .nav-link {
	color: rgb(111 111 111 / 30%);
	font-weight: 700;
	font-size: 17px;
	border: 0px;
	padding: 0;
	padding-bottom: 5px;
}
.tabsList .nav-item .nav-link.active {
	position: relative;
	color: #0b6b3f;
}
.tabsList .nav-item .nav-link.active:before {
	content: "";
	width: 100%;
	height: 3px;
	background-color: #0b6b3f;
	position: absolute;
	left: 0px;
	bottom: 0px;
	border-radius: 100px;
}
.tabsArea {
	width: 100%;
}
.tabsArea .tab-content {
	padding-top: 10px;
	max-height: calc(100vh - 230px);
	overflow-y: auto;
	padding-right: 1px;
}

.tabsArea .tab-content::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.tabsArea .tab-content::-webkit-scrollbar-track {
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d4d4d4;
	border-radius: 100px;
}

.SignInArea {
	background: linear-gradient(0deg, #009550 7.79%, #009550b3 77.41%);
	width: 100%;
	height: calc(100vh - 40px);
	border-radius: 20px;
	position: relative;
	overflow: hidden;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.FormBgShape {
	position: absolute;
	top: 0px;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.tabsArea .formArea {
	padding-right: 12px;
}
.signinContent {
	position: relative;
	z-index: 2;
}
.signinContent.contentCenter {
	text-align: center;
	margin-bottom: 60px;
}

.signinContent .h5Text {
	color: var(--white);
	font-size: 22px;
	font-weight: 700;
	max-width: 320px;
	line-height: 30px;
	margin-bottom: 15px;
}
.signinContent .pText {
	color: var(--white);
	font-size: 14px;
	padding-right: 30px;
	margin-bottom: 20px;
}
.signinContent.contentCenter h5 {
	max-width: 100% !important;
}
.signinContent.contentCenter .pText {
	max-width: 400px;
	margin: 0 auto 15px;
}
.signInBtn {
	width: 240px;
	background-color: var(--white);
	height: 46px;
	color: #0b6b3f;
	font-weight: 500;
	text-align: center;
	border: 1px solid var(--white);
	font-size: 16px;
}
.signinContent .signInBtn:hover,
.signinContent .signInBtn:focus {
	color: var(--green);
	background-color: var(--white);
	border: 1px solid var(--white);
	box-shadow: unset;
}
.smh6Text {
	color: var(--dark-gray-1);
	font-size: 16px;
}
.frmInfoBox .smh6Text {
	color: #1b1b1b;
	font-weight: 600;
	margin-bottom: 5px;
}
.smh5Title {
	color: #212529;
	font-size: 13px;
	margin-bottom: 10px;
}
.frmInfoBox .pText {
	color: #1b1b1b;
	margin-bottom: 3px;
	font-weight: 400;
	font-size: 13px;
}
.infoLink {
	color: var(--green);
	text-decoration: none;
	font-size: 12px;
	word-break: break-all;
	line-height: 16px;
}
.infoLink:hover {
	color: var(--green);
}
.contactInfoArea .infoRow {
	align-items: flex-end;
}
.infoItalicText {
	font-size: 12px;
	color: #212529;
	font-weight: 500;
	font-style: italic;
}
.infoItalicText span {
	color: var(--green);
}
.frmInfoBox {
	margin-bottom: 15px;
}
.contactInfoArea {
	background-color: var(--light-gray-1);
	padding: 25px 20px;
	border-radius: 10px;
	margin-bottom: 15px;
}
.mdh4Title {
	font-size: 17px;
	font-weight: 600;
	color: var(--dark-gray-1);
}

/*..New css Start...*/
.JoinExistingBox {
	display: none;
}
.signInLinkText {
	color: #0b6b3f;
	font-size: 14px;
	padding: 0px 5px;
	font-weight: 700;
}
.signInLinkText:hover {
	color: var(--green);
}
/*..New css End...*/
.formArea .text-danger {
	font-size: 12px;
	font-weight: 500;
}
.smLabelText {
	color: var(--dark-gray-1);
	font-weight: 300;
}

/* Responsive css Start */
.mobSignBtn {
	position: absolute;
	right: 0px;
	top: 8px;
	display: none;
}
.mobSignBtn .signInBtn {
	width: fit-content;
	background-color: unset;
	border: 0px;
	color: #0b6b3f;
	font-size: 20px;
	font-weight: 700;
	padding: 0px;
	height: unset;
}

.signInMoal.modal .modal-dialog {
	height: 100%;
	display: flex;
	align-items: center;
}

.multiSelectTagsBox {
	padding: 0px;
	display: flex;
	gap: 6px;
	flex-wrap: wrap;
	padding-bottom: 10px;
}
.multiSelectItem {
	width: fit-content;
	padding: 5px 10px;
	border: 0px;
	border: 1px solid #019550;
	border-radius: 5px;
	display: flex;
	align-items: center;
	gap: 20px;
	background-color: #e6f5ee;
}
.multiSelectItem span {
	font-size: 12px;
	color: #019550;
}
.multiSelectItem .crossBtn {
	border: 0px;
	background-color: unset;
}
.legalDocumentModal .modalCardBox iframe {
	min-height: calc(100vh - 180px);
}
.formContainer .form-check .form-check-label span {
	color: #009550b3;
	font-size: 12px;
	font-weight: 600;
}
.formContainer .form-check .form-check-label span:hover {
	border-bottom: 1px solid #009550;
}
 .form-check .form-check-label {
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	color: #838383;
}

.formContainer .form-check .form-check-input:checked {
	background-color: #009550;
	border-color: #009550;
}
.formContainer .form-check .form-check-input:focus {
	box-shadow: unset;
	border-color: #009550;
}
