.pageHead {
  width: 100%;
  margin-bottom: 20px;
}
.pageTitle {
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}
.pgText {
  font-size: 13px;
  color: #1b1b1b;
  font-weight: 400;
  margin-bottom: 0px;
}
.underLineTabsList.nav-tabs {
  width: 100%;
  border: 0px;
  display: flex;
  gap: 24px;
  align-items: center;
}
.underLineTabsList.nav-tabs .nav-link {
  padding: 0px;
  border: 0px;
  color: #717171;
  font-weight: 600;
  font-size: 16px;
}
.underLineTabsList.nav-tabs .nav-link.active {
  background-color: unset;
  color: #1b1b1b;
  position: relative;
}
.underLineTabsList.nav-tabs .nav-link.active::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--primary);
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 100px;
}
.pageCard {
  padding: 20px;
  background-color: var(--white);
  border-radius: 10px;
}
.inputField {
  height: 44px;
  border: 1px solid #cfd4d9;
  font-size: 14px;
  box-shadow: unset !important;
}
.inputField:focus {
  border: 1px solid #cfd4d9;
}
.inputField::-ms-input-placeholder {
  /* Edge 12-18 */
  color: var(--dark-gray-1);
  font-size: 14px;
  font-weight: 500;
}

.inputField::placeholder {
  color: var(--dark-gray-1);
  font-size: 14px;
  font-weight: 500;
}
.inputField.bdr2 {
  border: 1px solid #1b1b1b;
  border-radius: 5px;
}
.inputField.bdr2:focus {
  border: 1px solid #1b1b1b;
}
.frmSmtext {
  font-size: 12px;
  margin-bottom: 10px;
}
.uploadImg {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}
.uploadImgBox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.uplaodinfo h3 {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--dark-gray-1);
}
.uplaodinfo p {
  font-size: 14px;
  color: var(--dark-gray-1);
}
.userdefaultView {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
  display: block;
  background-color: var(--light-gray-1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #1b1b1b;
  font-size: 20px;
}
.fillPrimarBtn {
  background-color: var(--primary);
  color: white;
  font-size: 16px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  width: 150px;
  height: 40px;
  display: block;
}
.fillPrimarBtn.mrLauto {
  margin-left: auto;
}

/*...Edit btn css...  */
.pCardHead {
  position: relative;
}
.frmEditBtn {
  width: 48px;
  height: 36px;
  border: 1.5px solid #1b1b1b;
  font-size: 13px;
  font-weight: 600;
  background-color: unset;
  border-radius: 5px;
  color: #1b1b1b;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  justify-content: center;
}
.companyImg {
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}
.backArrow {
  cursor: pointer;
}
.stateSelect > div:nth-child(3) > div:first-child > div {
  color: var(--dark-gray-1);
  font-size: 15px;
  font-weight: 400 !important;
}
.programSelect > div:nth-child(3) > div:first-child > div {
  color: var(--dark-gray-1);
  font-size: 15px;
  font-weight: 400 !important;
}
.multiSelectTagsBox.color2 {
  padding: 10px 0px;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}
.multiSelectItem.color2 {
  width: fit-content;
  padding: 5px 10px;
  border: 0px;
  border: 1px solid #0082fc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #e6f5ee;
}
.multiSelectItem.color2 span {
  font-size: 12px;
  color: #0082fc;
}
.multiSelectItem.color2 .crossBtn {
  border: 0px;
  background-color: unset;
}
