.mdRadiolBtn {
  background-color: var(--primary);
  color: var(--white);
  font-size: 12px;
  width: fit-content;
  width: fit-content;
  border: 1px solid var(--primary);
  border-radius: 100px;
  padding: 2px 20px;
  margin: 0 auto;
  display: block;
}
.frmModalArea.smModal .modal-dialog {
  max-width: 400px;
}
.frmModalArea.smModal .modal-dialog .btn-close {
  display: none;
}
.mdlErrorIcon {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  object-fit: cover;
  display: block;
  margin: 0 auto 12px;
}
.modalCardBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalCardBox h5 {
  margin: 0px;
  color: var(--dark-gray-1);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}
.modalCardBox p {
  margin: 0px;
  color: var(--dark-gray-1);
  font-size: 12px;
  font-weight: 400;
}
.modalCardBox .bdrBtn {
  background-color: unset !important;
  border: 1.3px solid #c8c8c8 !important;
  font-size: 16px;
  height: 40px;
  width: 150px;
  border-radius: 4px;
  font-weight: 500;
  color: #1b1b1b !important;
}

.modalCardBox .fillPrimarBtn {
  background-color: var(--primary);
  color: white;
  font-size: 16px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  width: 150px;
  height: 40px;
  display: block;
}
.frmModalArea.smModal.frmModalArea .modal-dialog {
  max-width: 580px !important;
}
.frmModalArea.smModal.frmModalArea .modalCardBox.mdlAlignLeft h5 {
  text-align: left !important;
  display: block;
  width: 100%;
  font-size: 18px;
}
.mdlTextArea {
  min-height: 120px;
  width: 100%;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  outline: none !important;
}
.frmModalArea.smModal.frmModalArea .modalCardBox.mdlAlignLeft {
  align-items: flex-start !important;
}
.frmModalArea.smModal.frmModalArea .rtBtnsFlex {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.frmModalArea.smModal.frmModalArea .rtBtnsFlex .cancelBtn {
  background-color: unset !important;
  color: #1b1b1b !important;
}
.frmModalArea.smModal.frmModalArea .mdlErrorIcon {
  margin: unset;
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}
.frmModalArea.smModal.frmModalArea .modal-dialog .btn-close {
  display: block;
}
.frmModalArea.smModal.changePlanModal .modalCardBox h5 {
  text-align: center !important;
}
.frmModalArea.smModal.changePlanModal .rtBtnsFlex .cancelBtn {
  justify-content: center !important;
}
.frmModalArea.smModal.changePlanModal .modalCardBox p {
  text-align: center !important;
}
.fade.modal-backdrop {
  z-index: 999999;
}

.fade.frmModalArea.smModal.modal {
  z-index: 999999;
}
