.pageCard{
padding: 20px;
background-color: var(--white);
border-radius: 10px;
}
.smH5title{
font-size: 15px;
color: #1B1B1B;
font-weight: 700;
margin-bottom: 5px;
}
.mdText{
    font-size: 14px;
    color: var(--dark-gray-1);
}