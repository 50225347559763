.loaderOverlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 30%);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}
.loaderShape {
  width: 68px;
  height: 62px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.loaderOverlay .spinner-border {
  border: 5px solid var(--green);
  border-right-color: transparent;
  width: 45px;
  height: 45px;
}
