.modalArea .strokeBtn {
	border: 1.5px solid var(--primary);
	border-radius: 4px;
	color: var(--primary);
	background-color: unset;
	box-shadow: unset;
	padding: 0px 15px;
	height: 40px;
	font-size: 16px;
	font-weight: 500;
}
.modalArea .strokeBtn:hover,
.modalArea .strokeBtn:hover {
	background-color: unset;
	color: var(--primary);
}
.frmModalArea .modal-dialog {
	max-width: 700px;
}
.frmModalArea .modal-dialog .modal-content {
	border-radius: 20px;
	padding: 20px;
}
.h5Title {
	font-size: 18px;
	font-weight: 700;
	color: var(--dark-gray-1);
}
.mdlTextMaxWd {
	max-width: 420px;
}
.frmModalArea .modal-dialog .modal-content .modal-header {
	padding: 0px;
	border: 0px;
}
.frmModalArea .modal-dialog .modal-content .modal-header .btn-close {
	background-image: url("assets/images/icons/cross-icon.svg");
	padding: 0px;
	width: 36px;
	height: 36px;
	background-size: 36px;
	background-repeat: no-repeat;
	position: absolute;
	top: 40px;
	right: 40px;
	z-index: 9;
}

@media (max-width: 768px) {
	.modal-body .h5Title {
		padding-right: 34px;
	}
}
