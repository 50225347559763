/* setting siderbar css start */
.settingSidebar {
	background-color: var(--white);
	width: 224px;
	min-width: 224px;
	position: relative;
}
.stSidebarList {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0px;
}
.stSidebarList .stSidebarItem {
	text-align: left;
	text-decoration: none;
	padding: 12px 20px;
	display: block;
	width: 100%;
	color: #acacac;
	font-weight: 600;
	font-size: 16px;
}
.stSidebarList .stSidebarItem.active {
	background-color: rgb(0 133 255 / 6%);
	color: var(--primary);
	border-left: 6px solid var(--primary);
}
/* setting siderbar css start */

.stSidebarFooter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px 0px 20px;
}
.stSidebarFooterBtn {
	background-color: unset;
	border: none;
	color: var(--primary);
	font-weight: 600;
	font-size: 13px;
	padding: 0;
	cursor: pointer;
}
