.tableArea {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.tableArea thead tr th,
.tableArea tbody tr td {
  background-color: var(--light-gray-1);
  border-top: 1px solid #e3e4e4;
  border-bottom: 1px solid #e3e4e4;
}
.tableArea thead tr th:first-child,
.tableArea tbody tr td:first-child {
  border-radius: 5px 0px 0px 5px !important;
  border-left: 1px solid #e3e4e4;
}
.tableArea thead tr th:last-child,
.tableArea tbody tr td:last-child {
  border-radius: 0px 5px 5px 0px !important;
  border-right: 1px solid #e3e4e4;
}
.tblText {
  color: var(--dark-gray-1);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.tableArea thead tr {
  height: 30px;
}
.tableArea thead tr th:last-child {
  width: 270px;
}
.tableArea tbody tr td {
  height: 50px;
}
.tableSpace {
  display: block;
  width: 100%;
  height: 5px;
}
.tableArea.pricingTable thead tr th:last-child {
  width: auto;
}
.mdRadiolBtn {
  background-color: var(--primary);
  color: var(--white);
  font-size: 12px;
  width: fit-content;
  width: fit-content;
  border: 1px solid var(--primary);
  border-radius: 100px;
  padding: 2px 20px;
  margin: 0 auto;
  display: block;
}
.chooseBtn {
  background-color: unset;
  border: 0px;
  color: var(--primary);
  font-size: 14px;
  text-decoration: underline;
  display: block;
  margin: 0 auto;
}

.form-check.checkBoxbar {
  display: flex;
  gap: 5px;
  align-items: center;
}
.checkBoxbar .checkLabel {
  font-size: 12px;
  color: #838383;
  font-weight: 400;
  position: relative;
  top: 2px;
}
.form-check.checkBoxbar .circleCheck {
  width: 16px;
  height: 16px;
  border-radius: 100px;
}
.form-check.checkBoxbar .form-check-input:checked[type="checkbox"] {
  background-image: url("assets/images/icons/check-icon.svg");
  background-size: 9px;
}
.checkboxList {
  width: 100%;
  margin-bottom: 20px;
}
.checkListTtile {
  color: #1b1b1b;
  font-size: 12px;
  font-weight: 15px;
  margin-bottom: 5px;
}
.billingBtn {
  background-color: var(--primary);
  color: var(--white);
  font-size: 14px;
  border: 1px solid var(--primary);
  padding: 0px 24px;
  height: 44px;
  border-radius: 4px;
}
.checkListTtile.bold700 {
  font-weight: 700;
}

.pricingTable tbody tr.active td {
  border: 1.5px solid #0084ff !important;
  border-left: 0px !important;
  border-right: 0px !important;
}
.pricingTable tbody tr.active td:first-child {
  border-left: 1.5px solid #0084ff !important;
  position: relative;
}
.pricingTable tbody tr.active td:last-child {
  border-right: 1.5px solid #0084ff !important;
}

.tableArea thead tr th:first-child,
.tableArea tbody tr td:first-child {
  padding-left: 30px;
}

.pricingTable tbody tr.active td:first-child:before {
  content: "";
  background-image: url("assets/images/icons/circle-check-icon.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  position: absolute;
  left: 10px;
  top: 14px;
  width: 18px;
  height: 18px;
}
.pricingTable tbody tr:hover {
  cursor: pointer;
}
.paymentCard {
  display: flex;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #e3e4e4;
  min-height: 66px;
  gap: 6px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  cursor: pointer;
  width: 300px;
}
.paymentCard .CheckIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
  display: none;
}
.billingBtn.addCardBtn {
  font-size: 16px;
  width: 200px;
  height: 44px;
}
.paymentCard.active .CheckIcon {
  display: block;
}
.paymentCard.active {
  border: 1px solid var(--primary) !important;
}
.paymentCard .cartImgBox {
  width: 43px;
  height: 43px;
  border-radius: 5px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymentCard .cartImgBox img {
  width: 36px;
  height: 36px;
  object-fit: contain;
  object-position: center;
}
.cartInfoBar p {
  margin-bottom: 0px;
}
.paymentIcon {
  width: 37px;
  height: 22px;
  object-fit: contain;
}
.cartInfoBar {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 5px;
}
.cartInfoTextBar {
  display: flex;
  align-items: center;
  gap: 15px;
}
.cartInfoTextBar p {
  margin: 0px;
}
.cartInfoTextBar {
  margin: 0px;
}
.toggleDropdown .dropdown-toggle {
  background-color: #fff !important;
  border: 0px !important;
  padding: 0px !important;
  width: 16px !important;
}
.toggleDropdown .dropdown-toggle:after {
  display: none;
}
.cartInfoTextBar p {
  font-size: 11px;
}
.cartInfoTextBar span {
  display: block;
  padding: 2px 15px;
  background-color: #0082fc;
  color: #fff;
  font-size: 10px;
  border-radius: 100px;
}
.cartInfoBar > p {
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}
.noteBar {
  width: 100%;
  min-height: 60px;
  background-color: #f6f6f6;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  border-radius: 10px;
  margin-bottom: 24px;
}
.noteBar p {
  color: #212529;
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
  display: flex;
  gap: 10px;
  line-height: 20px;
}
.noteBar p span {
  font-weight: 800;
}
.textboxBar {
  padding: 20px 15px;
  background-color: rgb(249 203 37 / 8%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.textboxBar p {
  color: #c59600;
  font-size: 13px;
  line-height: 19px;
  margin: 0px;
}
.listCardRow {
  display: flex;
  flex-flow: wrap;
  gap: 15px;
}
.chooseBtn.textLine {
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.textLine .textLineNone {
  text-decoration: none !important;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
}
.textLine p:first-child {
  text-decoration: underline !important;
  font-weight: 600 !important;
  font-size: 15px;
  margin: 0px;
}
.chooseBtn.text-danger p {
  margin: 0px;
  color: #ff0000;
}
.chooseBtn p {
  margin: 0px;
}
.tableArea .chooseBtn {
  margin: unset;
  margin-left: auto;
}
.tableScrollArea table tr td:last-child,
.tableScrollArea table tr th:last-child {
  padding-right: 30px !important;
}
.tableScrollArea table tr th:last-child p {
  text-align: right;
}

.ml-4 {
  margin-left: 4px !important;
}
.fontSmall {
  font-size: 10px;
  display: block;
  line-height: 12px;
  margin: 4px 0px;
}
.pricingTable.pricingTableth tr th:first-child {
  width: 180px !important;
}

.h5MdTitle {
	font-size: 16px !important;
	font-weight: 700 !important;
	color: #1b1b1b !important;
}