.modalBody.modal-body {
  padding: 0px;
  position: relative;
  padding: 40px 30px;
  position: relative;
  overflow: hidden;
}
.cModalContentBox img {
  width: 90px;
  height: 90px;
  object-fit: contain;
}
.cModalContentBox h3 {
  font-size: 20px;
  font-weight: 800;
  color: #212529;
  margin-bottom: 6px;
}
.cModalContentBox .pText {
  font-size: 14px;
  color: #212529;
  font-weight: 400;
  line-height: 21px;
}
.checkCircleBg {
  position: absolute;
  bottom: -50px;
  right: -80px;
  width: 280px;
  height: 280px;
  object-fit: contain;
  opacity: 0.1;
}
.modalFtGrpBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.filBtnl.primerybtn {
  background-color: #0082fc;
  border: 1px solid #0082fc;
  border: 4px;
}

.congratulationModal .modal-dialog .btn-close {
  background-image: url("assets/images/icons/cross-icon.svg");
  padding: 0;
  background-size: 34px;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 12px;
  right: 18px;
}
.congratulationModal .modal-dialog .modal-header {
  border: 0px;
  position: relative;
  display: none;
}
.congratulationModal .modal-dialog .modal-content {
  border-radius: 20px;
}
.modalFtGrpBtn .bdrBtn {
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  font-size: 14px;
  color: #1b1b1b !important;
  width: 110px;
  height: 44px;
  background-color: #fff !important;
  position: relative;
  z-index: 9;
  font-weight: 500;
}
.modalFtGrpBtn .fillBtn {
  height: 44px;
  background-color: #0082fc !important;
  border: 1px solid #0082fc !important;
}
