.mdRadiolBtn {
  background-color: var(--primary);
  color: var(--white);
  font-size: 12px;
  width: fit-content;
  width: fit-content;
  border: 1px solid var(--primary);
  border-radius: 100px;
  padding: 2px 20px;
  margin: 0 auto;
  display: block;
}
.frmModalArea.smModal .modal-dialog {
  max-width: 400px;
}
.frmModalArea.smModal .modal-dialog .btn-close {
  display: none;
}
.mdlErrorIcon {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  object-fit: cover;
  display: block;
  margin: 0 auto 12px;
}
.modalCardBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalCardBox h5 {
  margin: 0px;
  color: var(--dark-gray-1);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}
.modalCardBox p {
  margin: 0px;
  color: var(--dark-gray-1);
  font-size: 12px;
  font-weight: 400;
}
.modalCardBox .bdrBtn {
  background-color: unset;
  border: 1.3px solid #c8c8c8;
  font-size: 16px;
  height: 40px;
  width: 150px;
  border-radius: 4px;
  font-weight: 500;
  color: #1b1b1b;
}
.modalCardBox .doneBtn .fillPrimarBtn {
  background-color: var(--primary);
  color: white;
  font-size: 16px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  width: 300px;
  height: 40px;
  display: block;
}
.frmModalArea.smModal .modalCardBox h5 {
  text-align: center !important;
}
.frmModalArea.smModal.notificationModal .modal-dialog {
  max-width: 380px !important;
}
