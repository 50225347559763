.ftContent {
	width: fit-content !important;
}
.tableScrollArea .measureTable tr td:first-child,
.tableScrollArea .measureTable tr td:nth-child(2),
.tableScrollArea .measureTable tr td:last-child {
	padding: 0 !important;
}
.tableScrollArea .measureTable tr td:first-child input,
.tableScrollArea .measureTable tr td:nth-child(2) input {
	max-width: unset !important;
}
.tblActionBtn {
	width: 30px;
	height: 30px;
	background-color: unset;
	border: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	display: block;
	margin: 0 auto;
}
.tblActionBtn img {
	width: 17px;
	height: 19px;
	object-fit: contain;
}
.tableScrollArea.costingTableArea.verticalScroll .measureTable tbody tr td:first-child,
.tableScrollArea.costingTableArea.verticalScroll .measureTable thead tr th:first-child {
	width: 50px !important;
	max-width: 50px !important;
	min-width: 50px !important;
	text-align: center;
	position: sticky;
	left: 0px;
}
.tableScrollArea.costingTableArea.verticalScroll .measureTable tbody tr td:first-child {
	background-color: #fff;
}
.tableScrollArea.costingTableArea.verticalScroll .measureTable tbody tr td:nth-child(2),
.tableScrollArea.costingTableArea.verticalScroll .measureTable thead tr th:nth-child(2) {
	left: 50px;
	min-width: 220px;
	max-width: 220px;
}
.tableScrollArea.costingTableArea.verticalScroll .measureTable tbody tr td:nth-child(3),
.tableScrollArea.costingTableArea.verticalScroll .measureTable thead tr th:nth-child(3) {
	position: sticky;
	left: 268px;
	min-width: 150px;
	max-width: 150px;
}
.btnGroup {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 5px;
}

.tblHeaderArea .tblLeftClm .form-check {
	font-size: 14px;
}
.tblHeaderArea .tblLeftClm .form-check-input:focus,
.tblHeaderArea .tblLeftClm .form-check-input {
	width: 17px;
	height: 17px;
	box-shadow: unset;
	outline: none;
}

.pageCard .tblHeaderArea {
	margin-bottom: 15px;
}
@media (max-width: 992px) {
	.pageCard .tblHeaderArea {
		flex-direction: column;
	}
}
@media (max-width: 768px) {
	.tblHeaderArea.frmActionHead .tblRightClm {
		flex-direction: column;
		width: 100%;
		max-width: unset;
	}
	.tblHeaderArea.frmActionHead .tblRightClm .tblSearchBar,
	.tblHeaderArea.frmActionHead .tblRightClm .tblSearchBar .searchField {
		width: 100%;
		max-width: unset;
	}
	.tblSelectInput > div {
		width: 100% !important;
	}
	.tableScrollArea.costingTableArea table th:first-child,
	.tableScrollArea.costingTableArea table td:first-child {
		position: relative;
		left: 0px;
	}
	.tableScrollArea.costingTableArea table th:nth-child(2),
	.tableScrollArea.costingTableArea table td:nth-child(2) {
		position: relative;
		left: 0px;
	}
	.tblTd.thirdTdSticky,
	.tblTh.thirdTdSticky {
		position: relative;
		left: 0px;
	}
}
